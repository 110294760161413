import { CloseIcon, DeleteIcon, PhoneIcon } from "@chakra-ui/icons";
import { Button, Container } from "@chakra-ui/react";
import React from "react";
import Title from "../components/global/Title";
function ActionsButtonBox() {
  const logout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  return (
    <Container my={10}>
      <Title>Actions</Title>
      <a href="https://wa.me/237695762595?text=Salut l'équipe Com'">
        <Button
          mt={4}
          size={"lg"}
          textAlign={"left"}
          variant="outline"
          leftIcon={<PhoneIcon />}
          colorScheme={"whatsapp"}
          w="100%"
        >
          Contacter l'équipe
        </Button>
      </a>
      <Button
        onClick={logout}
        mt={4}
        size={"lg"}
        textAlign={"left"}
        variant="outline"
        leftIcon={<CloseIcon />}
        colorScheme={"gray"}
        w="100%"
      >
        Se déconnecter
      </Button>
      {/* <Button
        mt={4}
        size={"lg"}
        textAlign={"left"}
        variant="outline"
        leftIcon={<DeleteIcon />}
        colorScheme={"red"}
        w="100%"
      >
        Quitter définitivement
      </Button> */}
    </Container>
  );
}

export default ActionsButtonBox;
