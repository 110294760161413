import { Box, Container, Flex, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TransactionLine from "../components/campaigns/TransactionLine";
import Badge from "../components/global/Badge";
import Title from "../components/global/Title";
import RemoveMoneyButton from "../components/transactions/RemoveMoneyButton";
import { initiatePayment, useGlobalDispatch, useGlobalState } from "../store/GlobalStore";
import { transaction_status } from "../utils/constants";

function TransactionsBox() {
  const { user, payment_initiated, transactions } = useGlobalState();
  const dispatch= useGlobalDispatch();
  const [activeStatus, setActiveStatus] = useState("all");
  const toast = useToast();
  const filter = (_filter) => {
    setActiveStatus(_filter);
  };
  const sendMoneyToUser = (amount, closeModal) => {
    initiatePayment(dispatch, user, amount)
    closeModal()
  }

  useEffect(() => {
    if(payment_initiated){
      toast({
        title:"Paiement initié",
        description: "Votre paiement a été initié avec succès. Vous le recevrez dans quelques minutes. Si ce n'est pas le cas, contactez l'équipe par Whatsapp uniquement au +237 6 95 76 25",
        isClosable: true,
        duration: 9000,
        status: "success",
      });
      dispatch({type: 'payment_initiated', payload: undefined})
    }
  }, [payment_initiated])
  return (
    <Container my={10}>
      <Title>Transactions</Title>
      <Box textAlign={"right"}>
        <RemoveMoneyButton user={user} initiatePayment={sendMoneyToUser}/>
      </Box>
      {transactions && transactions.length === 0 && (
        <Box my={20} textAlign={"center"}>
          <Text>Aucune somme n'a encore été retiré.</Text>
        </Box>
      )}
      {transactions && transactions.length > 0 && (
        <>
          <Flex wrap={"wrap"} my="4">
            <Badge
              onClick={() => {
                filter("all");
              }}
              active={activeStatus === "all"}
            >
              Toutes les transactions
            </Badge>
            <Badge
              onClick={() => {
                filter("pending");
              }}
              active={activeStatus === "pending"}
            >
              {transaction_status.pending}
            </Badge>
            <Badge
              onClick={() => {
                filter("done");
              }}
              active={activeStatus === "done"}
            >
              {transaction_status.done}
            </Badge>{" "}
            <Badge
              onClick={() => {
                filter("failed");
              }}
              active={activeStatus === "failed"}
            >
              {transaction_status.failed}
            </Badge>
          </Flex>
          <Box my="10">
            {transactions.map((transaction) => {
              return <TransactionLine transaction={transaction} key={Math.random()} />;
            })}
          </Box>
        </>
      )}
    </Container>
  );
}

export default TransactionsBox;
