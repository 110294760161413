import { Container, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Layout from "../components/global/Layout";
import TextNumber from "../components/global/TextNumber";
import ActionsButtonBox from "../partials/ActionsButtonBox";
import Breadcrumb from "../partials/Breadcrumb";
import InformationsBox from "../partials/InformationsBox";
import Statistics from "../partials/Statistics";
import TransactionsBox from "../partials/TransactionsBox";
import { getAllTransactions, getUser, useGlobalDispatch, useGlobalState } from "../store/GlobalStore";

function Account() {
  const { user } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const [stats, setStats] = useState([]);

  const breadcrumbItems = [
    {
      name: "Accueil",
      path: "/",
    },
    {
      name: "Account",
      path: "/account",
    },
  ];
  useEffect(() => {
    getUser(dispatch);
    getAllTransactions(dispatch);
  }, []);
  
  useEffect(() => {
    if (user && user.stats) {
      setStats([
        {
          label: "FCFA gagnés",
          value: user.stats.total_earned,
        },
        {
          label: "Clics recoltés",
          value: user.stats.total_clics,
        },
        {
          label: "Campagnes",
          value: user.stats.total_campaigns,
        },
      ]);
    }
  }, [user]);
  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />
      {user && (
        <Container>
          <Text fontWeight={700} fontSize={30}>
            Salut {user.first_name || user.name},
          </Text>
          <Text>Vous avez,</Text>
          <TextNumber my={10} number={user.balance + " XAF"} text={"disponibles"} />
        </Container>
      )}
      <Statistics stats={stats} />
      {user.last_name && user.email ? (
        <>
          <TransactionsBox />
          <InformationsBox />
        </>
      ) : (
        <>
          <InformationsBox />
          <TransactionsBox />
        </>
      )}
      <ActionsButtonBox />
    </Layout>
  );
}

export default Account;
