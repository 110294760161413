import {
  Alert,
  AlertDescription,
  AlertTitle,
  Badge,
  Box,
  Button,
  CloseButton,
  Container,
  Flex,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ErrorBox from "../components/global/ErrorBox";
import Layout from "../components/global/Layout";
import TextNumber from "../components/global/TextNumber";
import Title from "../components/global/Title";
import Breadcrumb from "../partials/Breadcrumb";
import ShareBox from "../partials/ShareBox";
import Statistics from "../partials/Statistics";
import {
  getCampaign,
  joinCampaign,
  useCampaignDispatch,
  useCampaignState,
} from "../store/CampaignStore";
import { useGlobalState } from "../store/GlobalStore";
import { primaryColor } from "../utils/constants";
import LoadingPage from "./LoadingPage";

function Campaign(props) {
  const { campaignUuid } = useParams();
  const { user } = useGlobalState();
  const { loading, error, campaign } = useCampaignState();
  const dispatch = useCampaignDispatch();
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const [stats, setStats] = useState([]);
  const dateFormat = "DD-MM-YYYY à HH:mm";

  useEffect(() => {
    getCampaign(dispatch, campaignUuid);
  }, [campaignUuid]);

  useEffect(() => {
    if (campaign) {
      setBreadcrumbItems([
        {
          name: "Accueil",
          path: "/",
        },
        {
          name: "Campagnes",
          path: "/",
        },
        {
          name: campaign.name,
          path: "/campaigns/" + campaign.uuid,
        },
      ]);
      if (campaign && campaign?.pivot?.user_id === user.id) {
        setStats([
          {
            label: "Clics",
            value: campaign.stats.clics,
          },
          {
            label: "FCFA gagnés",
            value: campaign.stats.amount,
          },
        ]);
      }
    }
  }, [campaign]);

  const [closeInfos, setCloseInfos] = useState(false);

  const onCloseInfos = () => {
    setCloseInfos(true);
  };

  const joinThisCampaign = () => {
    joinCampaign(dispatch, campaignUuid);
  };
  return (
    <Layout>
      {campaign && <Breadcrumb items={breadcrumbItems} />}
      {loading && <LoadingPage />}
      {error && <ErrorBox error={error} />}
      {campaign && (
        <>
          <Container>
            <Text fontSize={"3xl"} fontWeight={700}>
              {campaign.name}{" "}
              {campaign.is_active && <Badge colorScheme="green">Active</Badge>}
              {!campaign.is_active && <Badge colorScheme="red">Inactive</Badge>}
            </Text>
            <Text as="small">
              Créée le{" "}
              <Moment format={dateFormat}>{campaign.created_at}</Moment>
            </Text>
            <br />
          </Container>
          {!campaign.is_active && (
            <ErrorBox
              error={{
                title: "Attention",
                message:
                  "Cette campagne a été désactivée par son propriétaire. Tous les nouveaux clics ne seront plus comptabilisés.",
              }}
            />
          )}
        </>
      )}
      {campaign ? (
        campaign.pivot?.user_id === user.id ? (
          <>
            <Container>
              <Text as="small">
                Rejoins le{" "}
                <Moment format={dateFormat}>{campaign.joined_at}</Moment>
              </Text>
              <Box my="4">
                <Title>Créateur de la campagne</Title>

                <Text>
                  Cette campagne a été créée par{" "}
                  <strong>
                    {campaign?.owner?.name || "une entreprise."}
                    </strong>
                </Text>
                {!closeInfos && (
                  <Alert
                    my={4}
                    fontSize={15}
                    status="info"
                    variant="subtle"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <CloseButton
                      alignSelf="flex-end"
                      position="relative"
                      right={-1}
                      top={-1}
                      onClick={onCloseInfos}
                    />
                    <Box>
                      <AlertTitle>NOTICE</AlertTitle>
                      <AlertDescription>
                        Toutes les campagnes ne sont ni créées, ni gérées par
                        Com'. Elles sont créées et gérées par des entreprises.
                        Elles peuvent donc être retirées, modidifées ou
                        supprimées à tout moment par l'entreprise qui l'a créé.

                        Néanmoins, nous nous assurons que vos gains soient stockés et sauvegardés en temps réel.
                      </AlertDescription>
                    </Box>
                  </Alert>
                )}
              </Box>
            </Container>
            {campaign.meta?.short_url ? (
              <>
                <Statistics stats={stats} />
                <ShareBox campaign={campaign} user={user} />
              </>
            ) : (
              <ErrorBox
                error={{
                  title: "",
                  message:
                    "Cette campagne rencontre un soucis. Si le problème persiste, contactez-nous via Whatsapp uniquement au +237 6 95 76 25 95",
                }}
              />
            )}
          </>
        ) : (
          <Container>
            <Text my="5" as="p">
              {campaign.description || "Aucune description fournie."}
            </Text>
            <Flex wrap={"wrap"} justifyContent="space-around">
              <TextNumber number={campaign.ppc} text="FCFA/clic" />
              <TextNumber
                number={campaign.stats.total_participants.all}
                text="participants"
              />
            </Flex>
            <Button
              py={7}
              mt={5}
              bgColor={primaryColor}
              color="white"
              w="100%"
              onClick={joinThisCampaign}
            >
              Rejoindre cette campagne
            </Button>
            <Link to="/">
              <Button py={7} mt={5} bgColor={"#CCC"} color="white" w="100%">
                Revenir
              </Button>
            </Link>
          </Container>
        )
      ) : (
        <></>
      )}
    </Layout>
  );
}

export default Campaign;
