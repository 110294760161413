import { Badge, Box, Flex, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Moment from "react-moment";
import { darkGreen } from "../../utils/constants";
import IconText from "../global/IconText";
import "moment/locale/fr";
import { Link } from "react-router-dom";
import { useGlobalState } from "../../store/GlobalStore";

function CampaignCard({ campaign }) {
  const { color_theme } = useGlobalState();
  return (
    <Link to={"/campaigns/" + campaign.uuid}>
      <Box bgColor={color_theme === "light" ? "#F8F8F8" : "#333"} boxShadow={"base"} my="5" borderRadius={"10px"} overflow="hidden" minHeight="300px" cursor={"pointer"}>
        <Box width="100%" height="220px" bgColor={"#EEE"} overflow="hidden">
          <Image objectFit={"contain"} src={campaign?.media_thumb} fallbackSrc={campaign?.media?.original_url} alt="" w="100%" h="100%" objectPosition={"center"} />
        </Box>
        <VStack w="100%" minHeight="80px" pb={4}>
          <HStack alignItems={"flex-end"} justifyContent={"space-between"} px={"5%"} w="100%" h="60%">
            <Text color={darkGreen} fontSize="20px" fontWeight="600">
              {campaign.name}
              &nbsp; &nbsp;
              {campaign.is_active === 1 && <Badge colorScheme="green">Active</Badge>}
              {campaign.is_active === 0 && <Badge colorScheme="red">Inactive</Badge>}
            </Text>
            <Text fontSize={"25px"}>→</Text>
          </HStack>
          <Flex alignItems={"flex-start"} justifyContent="space-between" px={"5%"} w="100%" h="40%">
            {campaign.user ? (
              <HStack>
                <IconText icon={"assets/icons/banknotes.svg"} text={campaign.user.total_clics * campaign.ppc + " FCFA gagné(s)"} />
                <IconText icon={"assets/icons/chart.svg"} text={campaign.user.total_clics + " clic(s)"} />
              </HStack>
            ) : (
              <HStack>
                <IconText icon={"assets/icons/people.svg"} text={campaign.stats.total_participants.all + " participant(s)"} />
                <IconText icon={"assets/icons/offer.svg"} text={campaign.ppc + " FCFA/clic"} />
              </HStack>
            )}
            <Text fontSize={"12px"} display={{ base: "none", sm: "block" }}>
              <Moment fromNow locale="fr">
                {campaign.created_at}
              </Moment>
            </Text>
          </Flex>
        </VStack>
      </Box>
    </Link>
  );
}

export default CampaignCard;
